import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { SipProvider } from 'react-sip';

import App from './App';

const sipPassword = 'test.,2020';
ReactDOM.render(
  <SipProvider
    //host="192.168.1.202"
    host="asteriskcrm.athenacampaign.com"
    port={443}
    pathname="/WS" // Path in socket URI (e.g. wss://sip.example.com:7443/ws); "" by default
    user="1004"
    password={sipPassword} // usually required (e.g. from ENV or props)
    autoRegister={true} // true by default, see jssip.UA option register
    autoAnswer={false} // automatically answer incoming calls; false by default
    iceRestart={false} // force ICE session to restart on every WebRTC call; false by default
    sessionTimersExpires={120} // value for Session-Expires header; 120 by default
    extraHeaders={{ // optional sip headers to send
      register: ['X-Foo: foo', 'X-Bar: bar'],
      invite: ['X-Foo: foo2', 'X-Bar: bar2']
    }}
    iceServers={[]}
    debug={true} // whether to output events to console; false by default
  >
    <App />
  </SipProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



