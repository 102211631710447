import React, {useEffect } from 'react';
import './App.css';

//ws://192.168.1.202:8088/ws


//function App() {
function App () {
  useEffect(() => {
    //onload execute ....

  }, []);

  return (
    <div className="App">
      
      <h1>TEST REACT-SIP</h1>
      

    </div>
  );
}

export default App;
